//develpoment server
//const host = 'http://159.65.152.211:8000/'
// const host = 'http://165.22.210.216:8000/'
//const host = "https://ut-webapp3.do-blr.mpgpsdc.com/"
//UAT server
//const host = 'https://ut-app02.do-blr.mpgpsdc.com/'
//const host = 'http://139.59.13.222:8005/'
//Live Server
 //const host = 'http://139.59.78.109:8005/'
//live server
//const host = 'https://app3.matchpointgps.in/'
//testing server


const host = 'https://app05.do-blr.mpgpsdc.com/'

 //const host = 'http://142.93.221.30:8000/'
    
// const host = 'http://142.93.221.30:8004/'

//const host = 'https://ut-applb.matchpointgps.in/'
//   web3lb1.do-blr.mpgpsdc.com
   
//const host = "http://142.93.221.30:8005/"

//const host = 'https://web3lb1.do-blr.mpgpsdc.com/'

//const host = "http://139.59.3.180/"

//const host = "http://139.59.78.109:8002/"
//const host = "http://139.59.78.109:8005/"
//const host = 'https://app13.do-blr.mpgpsdc.com/'  

export default host
